import React, { useState, useEffect } from "react";
import "../../css/CustomStyles.css";
import { Api } from "../../remote/api";
import ChartComponent from "./components/ChartComponent";
import html2canvas from 'html2canvas';
import download from 'downloadjs';

export const Tab4Content = () => {

    const [highestErrorRates, setHighestErrorRates] = useState([]);
    const [highestLatencies, setHighestLatencies] = useState([]);
    const [highest50xErrors, setHighest50xErrors] = useState([]);
    const [highest40xErrors, setHighest40xErrors] = useState([]);

    const fetchReports = async () => {
        const result = await Api.getReports()

        if (result) {
            setHighestErrorRates(result.highestErrorRates);
            setHighestLatencies(result.highestLatencies);
            setHighest50xErrors(result.highest50xErrors);
            setHighest40xErrors(result.highest40xErrors);
        }
        console.log(result);
    }

    useEffect(() => {
        fetchReports();
      }, []);
  
      const handleDownload = (target) => {
        downloadDivAsImage(target, `${target}.png`);
      };

      const downloadDivAsImage = (divId, fileName) => {
        const element = document.getElementById(divId);
      
        html2canvas(element)
          .then(canvas => {
            const imageData = canvas.toDataURL('image/png');
            download(imageData, fileName);
          })
          .catch(error => {
            console.error('Error:', error);
          });
      };

  return (
    <div className="tab4-container">
      <div id="chart-errors">
      <ChartComponent data={highestErrorRates} title={'Error Rates - Top 5'} ylabel={'Average Errors (15 minutes)'} metric={'errors500'}/>
      </div>
      <button onClick={() => handleDownload('chart-errors')}>Download Chart</button>
      <div id="chart-latency">
      <ChartComponent data={highestLatencies} title={'Latency - Top 5'} ylabel={'Latency (ms)'} metric={'latencyInSeconds'}/>
      </div>
      <button onClick={() => handleDownload('chart-latency')}>Download Chart</button>
      <div id="chart-50x">
      <ChartComponent data={highest50xErrors} title={'50x Errors - Top 5'} ylabel={'Errors'} metric={'errors500'}/>
      </div>
      <button onClick={() => handleDownload('chart-50x')}>Download Chart</button>
      <div id="chart-40x">
      <ChartComponent data={highest40xErrors} title={'40x Errors - Top 5'} ylabel={'Errors'} metric={'errors400'}/>
      </div>
      <button onClick={() => handleDownload('chart-40x')}>Download Chart</button>
    </div>
  );
};
