import React from "react";
import "../css/HealthCheck.css"

const PopupImage = ({onClose, title, dismissMessage, url}) => {
  return (
    <div className="popup-overlay">
      <div className="popup">
        <div className="popup-content">
        <span className="popup-message">
          <h1>{title}</h1>
        </span>
        <button className="popup-image-close-button" onClick={onClose}>
            {dismissMessage}
          </button>
          <img
            src={url}
            alt={title}
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </div>
      </div>
    </div>
  );
};

export default PopupImage;
