import React, { useState, useEffect } from "react";
import "../../css/CustomStyles.css";
import { Api } from "../../remote/api";
import Timer from "../../lib/Timer";
import UsageTest from "./components/UsageTest";
import { HealthCheck } from "./components/HealthCheck";
import { SmokeTest } from "./components/SmokeTest/SmokeTest";
import { PaymentTest } from "./components/SmokeTest/PaymentTest";

export const Tab1Content = () => {
  const [smokeTestArray, setSmokeTestArray] = useState([]);
  const [isRunningSmokeTest, setIsRunningSmokeTest] = useState(false);
  const [systemMessage, setSystemMessage] = useState("");
  const [errorRateResponses, setErrorRateResponses] = useState([]);
  const [failingIntegrations, setFailingIntegrations] = useState([]);
  const [usageIntervals, setUsageIntervals] = useState([]);
  const [usageComment, setUsageComment] = useState("");
  const [usageResult, setUsageResult] = useState(false);

  useEffect(() => {
    handleSmokeTest();
  }, []);

  const getHealthCheck = async () => {
    const result = await Api.healthCheckTest();
    if (result) {
      setErrorRateResponses(result.errorRateResponses);
      setFailingIntegrations(result.failingIntegrations);
    }
  };

  const getUsageCheck = async () => {
    const result = await Api.usageCheckTest();
    if (result) {
      setUsageComment(result.comments);
      setUsageIntervals(result.data);
      setUsageResult(result.result);
    }
  };

  const handleSmokeTest = async () => {
    setSmokeTestArray([]);
    setErrorRateResponses([]);
    setFailingIntegrations([]);
    setIsRunningSmokeTest(true);
    setSystemMessage(
      "Sit tight, the full test could take up to 5 minutes to complete."
    );
    getHealthCheck();
    getUsageCheck();
    const result = await Api.smokeTest();

    if (result && !result.error) {
      setSmokeTestArray(result);
      setSystemMessage("");
    } else {
      setSystemMessage(result.error);
    }
    setIsRunningSmokeTest(false);
  };

  return (
    <div className="tab1-container">
      <div>
        <button onClick={handleSmokeTest} disabled={isRunningSmokeTest}>
          {isRunningSmokeTest ? "Running Tests..." : "Click Here to Run Tests"}
        </button>
      </div>
      {systemMessage !== "" && (
        <div
          className={`${
            !systemMessage.includes("failed")
              ? "system-message"
              : "system-message-failed"
          }`}
        >
          {systemMessage !== "" && <div>{systemMessage}</div>}
          <div>{isRunningSmokeTest && <Timer />}</div>
        </div>
      )}

      <div className="tab1-paymenttest-container">
        <PaymentTest />
      </div>

      <div className="tab1-smoketest-container">
        {<SmokeTest smokeTestArray={smokeTestArray} />}
      </div>
      <div className="tab1-healthCheck-container">
        {
          <HealthCheck
            errorRateResponses={errorRateResponses}
            failingIntegrations={failingIntegrations}
          />
        }
      </div>
      <div className="tab1-bargraph-container">
        {
          <UsageTest
            usageComment={usageComment}
            usageIntervals={usageIntervals}
            usageResult={usageResult}
          />
        }
      </div>
    </div>
  );
};
