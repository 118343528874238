export class Api {
  static accessToken = "";
  static sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static makePayment = async () => {
    console.log("[makePayment]: MAKING PAYMENT...");
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const url = `${process.env.REACT_APP_API_ENDPOINT}/paymentsTest`;

    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${this.accessToken}`,
          "x-api-key": apiKey,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        return data.data;
      } else {
        console.log(`[makePayment]: Failed with status ${response.status}`);
        return null;
      }
    } catch (error) {
      console.log(`[makePayment]: Failed with error ${error}`);
      return null;
    }
  };

  static getDowntime = async () => {
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const url = `${process.env.REACT_APP_API_ENDPOINT}/downtime`;

    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${this.accessToken}`,
          "x-api-key": apiKey,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        return data.data;
      } else {
        return null;
      }
    } catch {
      return null;
    }
  };

  static getTemplates = async () => {
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const url = `${process.env.REACT_APP_API_ENDPOINT}/templates`;

    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${this.accessToken}`,
          "x-api-key": apiKey,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        return data.data;
      } else {
        console.log("[Error] => getTemplates => Error 1 fetching templates")
        return null;
      }
    } catch {
      console.log("[Error] => getTemplates => Error 2 fetching templates")
      return null;
    }
  };

  static getAppConfig = async () => {
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const url = `${process.env.REACT_APP_API_ENDPOINT}/appconfig`;

    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${this.accessToken}`,
          "x-api-key": apiKey,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        return data.data;
      } else {
        return null;
      }
    } catch {
      return null;
    }
  };

  static updateAppConfig = async (
    config, 
    name
  ) => {
    let body;

    if (name === 'newFeature') {
      const newFeature = config;
      body = JSON.stringify({
        newFeature,
      });
    }

    if (name === 'disablePowerStatusMessage') {
      const disablePowerStatusMessage = config;
      body = JSON.stringify({
        disablePowerStatusMessage,
      });
    }

    if (name === 'maintenancePeriods') {
      const maintenancePeriods = config;
      body = JSON.stringify({
        maintenancePeriods,
      });
    }

    if (name === 'newUrgentMessage') {
      const newUrgentMessage = config;
      body = JSON.stringify({
        newUrgentMessage,
      });
    }

    if (name === 'stormMode') {
      const stormMode = config;
      body = JSON.stringify({
        stormMode,
      });
    }

    console.log(`BODY: ${body}`);

    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/appconfig`;

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          authorization: `Bearer ${this.accessToken}`,
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: body,
      });
      if (response.status === 200) {
        return true;
      } else {
        console.log("Unable to update config");
        return null;
      }
    } catch (error) {
      console.error("Unable to update config:", error);
      return null;
    }
  };

  static salesforceTest = async () => {
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const url = `${process.env.REACT_APP_API_ENDPOINT}/salesforce`;

    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${this.accessToken}`,
          "x-api-key": apiKey,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch (e) {
      console.log(JSON.stringify(e));
      return null;
    }
  };

  static healthCheckTest = async () => {
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const url = `${process.env.REACT_APP_API_ENDPOINT}/healthCheck`;

    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${this.accessToken}`,
          "x-api-key": apiKey,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        return data.comments;
      } else {
        return null;
      }
    } catch (e) {
      console.log(JSON.stringify(e));
      return null;
    }
  };

  static getReports = async () => {
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const url = `${process.env.REACT_APP_API_ENDPOINT}/weeklyReport`;

    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${this.accessToken}`,
          "x-api-key": apiKey,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch (e) {
      console.log(JSON.stringify(e));
      return null;
    }
  };

  static usageCheckTest = async () => {
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const url = `${process.env.REACT_APP_API_ENDPOINT}/usageCheck`;

    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${this.accessToken}`,
          "x-api-key": apiKey,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch (e) {
      console.log(JSON.stringify(e));
      return null;
    }
  };

  static smokeTest = async () => {
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const timeout = 120000;
    const url = `${process.env.REACT_APP_API_ENDPOINT}/smokeTest`;
    const maxRetries = 10;
    let retryCount = 0;

    while (retryCount < maxRetries) {
      console.log("[smokeTest]: TRYING...");
      try {
        const response = await fetch(url, {
          headers: {
            authorization: `Bearer ${this.accessToken}`,
            "x-api-key": apiKey,
          },
        });

        if (response.status === 200) {
          const data = await response.json();
          return data.data;
        } else {
          console.log(`[smokeTest]: Status ${response.status} => RETRYING ${retryCount} ...`);
          retryCount++;
          if (retryCount === maxRetries) {
            return { error: "Max retries reached. Request failed." };
          }
          await this.sleep(timeout);
        }
      } catch (error) {
        console.log(`[smokeTest]: ${error } => RETRYING...`);
        retryCount++;
        if (retryCount === maxRetries) {
          return { error: "[smokeTest]: Max retries reached. Request failed." };
        }
        await this.sleep(timeout);
      }
    }
  };

  static accountLinker = async (body) => {
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/accountFinder`;

    try {
      const response = await fetch(endpoint, {
        method: "PUT",
        headers: {
          authorization: `Bearer ${this.accessToken}`,
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify(body),
      });
      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        const data = await response.json();
        console.log(JSON.stringify(data));
        return null;
      }
    } catch (error) {
      console.error("Login failed:", error);
      return null;
    }
  };

  static accountFinder = async (body) => {
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/accountFinder`;

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          authorization: `Bearer ${this.accessToken}`,
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify(body),
      });
      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        const data = await response.json();
        console.log(JSON.stringify(data));
        return null;
      }
    } catch (error) {
      console.error("Login failed:", error);
      return null;
    }
  };

  static login = async (username, password) => {
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/login`;

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });
      if (response.status === 200) {
        const data = await response.json();
        const { accessToken } = data;
        this.accessToken = accessToken;
        console.log(accessToken);
        return true;
      } else {
        const data = await response.json();
        console.log(JSON.stringify(data));
        return null;
      }
    } catch (error) {
      console.error("Login failed:", error);
      return null;
    }
  };

  static getAccessToken = async (authorization) => {
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/callback`;
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": apiKey,
      "Authorization": `Bearer ${authorization}`
    };

    console.log("HEADERS", headers);

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers
      });
      const data = await response.json();
      console.log(JSON.stringify(data));
      if (response.status === 200) {
        const { accessToken } = data;
        this.accessToken = accessToken;
        console.log(accessToken);
        return true;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error while exchanging authorization for access token:", error);
      return null;
    }
  };
}
