import Spinner from "../Spinner";
import React from "react";
import { SmokeTestResultCell } from "./SmokeTestResultCell";

export const SmokeTest = ({ smokeTestArray }) => {
  return (
    <div>
      <center>
        <h2 className="welcome-message">Smoke Test (MOBILE):</h2>
      </center>
      {smokeTestArray.length === 0 && <Spinner />}
      <br></br>
      {smokeTestArray.map((test, index) => (
        <SmokeTestResultCell
          key={index}
          test={test}
          index={index}
          length={smokeTestArray.length}
        />
      ))}
    </div>
  );
};
