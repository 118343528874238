import React from "react";
import "../../../css/CustomStyles.css";
import "../../../css/UsageTestResult.css";

function UsageTest({ usageComment, usageIntervals, usageResult }) {
  return (
    <div className="bar-graph">
      
        <center>
          <h2 className="welcome-message">Usage (MOBILE):</h2>
        </center>
      
      {usageComment !== "" && (
        <center>
          <h2
            className="welcome-message"
            style={{ whiteSpace: "pre-line", width: "300px" }}
          >
            {usageComment}
          </h2>
          <div className={usageResult ? "test-passed" : "test-fail"}>
            <span className="test-label">{usageResult ? "PASS" : "FAIL"}</span>
          </div>
        </center>
      )}

      {usageIntervals.length > 1 &&
        usageIntervals.map((interval) => (
          <div className="tab1-row" key={interval.timestamp}>
            <div className="timestamp">{interval.timestamp}</div>
            <div className="bar-container">
              <div
                className="bar"
                style={{
                  width: `${interval.ratio * 100}%`,
                  backgroundColor: "red",
                  minWidth: "1px",
                }}
              >
                {`${(interval.ratio * 100).toFixed(0)}%`}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default UsageTest;
