import React, { useState, useEffect } from 'react';

const Timer = () => {
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const requestStartTime = Date.now(); 

    const timerId = setInterval(() => {
      const elapsed = Date.now() - requestStartTime;
      setElapsedTime(elapsed);
    }, 1000); 

    setStartTime(requestStartTime);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const formatTime = (time) => {
    const seconds = Math.floor(time / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div>
      <p>Elapsed time: {formatTime(elapsedTime)}</p>
    </div>
  );
};

export default Timer;