export const SmokeTestResultCell = ({ test, index, length }) => {
  return (
    <div className="tab1-row">
      <div className="tab1-subrow" style={{ animationDelay: `${index * 2}s` }}>
        <div className={`test-name`}>{test.endpoint}</div>
        <div className={`test-result`}>
          <div className={test.result ? "test-passed" : "test-fail"}>
            <span className="test-label">{test.result ? "PASS" : "FAIL"}</span>
          </div>
        </div>
        <div className={`test-details`}>{test.details}</div>
        {!test.result && (
          <div
            className="test-name"
            style={{ whiteSpace: "pre-line", width: "300px" }}
          >
            <br></br>
            {test.info}
          </div>
        )}
      </div>
      {index !== length - 1 && <hr className="test-separator" />}
    </div>
  );
};
