import React from 'react';
import '../css/Popup.css';

const Popup = ({ message, action, value, onCancel, onContinue }) => {
  return (
    <div className="popup-overlay">
      <div className="popup">
        <div className="popup-content">
          <span className="popup-message">{message}</span>
          <div className="popup-buttons">
            <button className="popup-buttons-cancel" onClick={() => onCancel(action, value)}>Cancel</button>
            <button className="popup-buttons-publish" onClick={() => onContinue(action, value)}>{action}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
