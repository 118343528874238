import PopupImage from "../../../lib/PopupImage";
import React, { useState } from "react";

export const HealthCheck = ({ errorRateResponses, failingIntegrations }) => {
  const [isPopupImageVisible, setPopupImageVisible] = useState(false);

  function handlePopupImage(state) {
    setPopupImageVisible(state);
  }
  return (
    <div>
      <div>
        {errorRateResponses.length > 1 && (
          <center>
            <h2 className="welcome-message">Error Rates (MOBILE):</h2>
          </center>
        )}
        <br></br>
        {errorRateResponses.map((response, index) => (
          <div className="tab1-row" key={index}>
            <div
              className="tab1-subrow"
              style={{ animationDelay: `${index * 2}s` }}
            >
              <div className={`test-name`}>{response.resourceShortName}</div>
              <div className={`test-result`}>
                <div
                  className={
                    !response.containsErrors ? "test-passed" : "test-fail"
                  }
                >
                  <span className="test-label">
                    {!response.containsErrors ? "PASS" : "FAIL"}
                  </span>
                </div>
              </div>
              {response.containsErrors && (
                <div
                  className={
                    !response.containsErrors ? "test-passed" : "test-fail"
                  }
                >
                  {response.errorRates.length > 0 && (
                    <span className="test-label">
                      Error Rate: {response.errorRates[0].errorRate * 100}%
                      {` (5 minutes ago)`}
                    </span>
                  )}
                  <br></br>
                  {response.errorRates.length > 1 && (
                    <span className="test-label">
                      Error Rate: {response.errorRates[1].errorRate * 100}%
                      {` (10 minutes ago)`}
                    </span>
                  )}
                </div>
              )}
              {response.containsErrors && (
                <div
                  className="test-name"
                  style={{ whiteSpace: "pre-line", width: "300px" }}
                >
                  <br></br>
                  {response.info}
                </div>
              )}
            </div>
            {index !== errorRateResponses.length - 1 && (
              <hr className="test-separator" />
            )}
          </div>
        ))}
      </div>
      {errorRateResponses && errorRateResponses.length > 1 && (
        <div>
          <center>
            <div className="integration-errors">
              <button onClick={()=> handlePopupImage(true)}>
                <h2 className="welcome-message">
                  {failingIntegrations && failingIntegrations.length > 1
                    ? "Integration Errors:"
                    : "No Integration Errors found!"}
                </h2>
              </button>
            </div>
            {isPopupImageVisible && (
              <PopupImage
                onClose={() => handlePopupImage(false)}
                title="Mobile App/myEntergy/MSC Data Flow"
                dismissMessage="Click Here to Close Diagram"
                url="DATA_FLOW_OFFICIAL.png"
              />
            )}
            <div className={`test-result`}>
              <div
                className={
                  failingIntegrations && failingIntegrations.length > 1
                    ? "test-fail"
                    : "test-passed"
                }
              >
                <span className="test-label">
                  {failingIntegrations && failingIntegrations.length > 1
                    ? "FAIL"
                    : "PASS"}
                </span>
              </div>
            </div>
          </center>

          {failingIntegrations && failingIntegrations.length > 1 && (
            <div>
              {failingIntegrations.map((failure, index) => (
                <div className="tab1-row" key={index}>
                  <div
                    className="tab1-subrow"
                    style={{ animationDelay: `${index * 2}s` }}
                  >
                    <div className={`test-name`}>
                      URL: {failure.request.url}
                    </div>
                    <div className={`test-name`}>
                      METHOD: {failure.request.method}
                    </div>
                    <div className={`test-name`}>
                      STATUS: {failure.response.status}
                    </div>
                  </div>
                  {index !== errorRateResponses.length - 1 && (
                    <hr className="test-separator" />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
