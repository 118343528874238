import React, { useState, useEffect } from "react";
import Popup from "../../../../lib/popup";
import Spinner from "../Spinner";
import { Api } from "../../../../remote/api";
import { PaymentResultCell } from "./PaymentResultCell";

export const PaymentTest = () => {
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [paymentTestResult, setPaymentTestResult] = useState({
    endpoint: "",
    result: false,
    details: "",
    info: "",
  });

  const makePayment = async () => {
    setIsPaymentInProgress(true);
    const result = await Api.makePayment();
    setIsPaymentInProgress(false);
    if (!result) {
      setPaymentTestResult((prevState) => (
        {
            ...prevState,
            details: "A Network Error Has Occurred"
        }
      ));
    } else {
      setPaymentTestResult(result)
    }
  };

  const onContinueConfirmation = (action, section) => {
    setIsConfirmationModalVisible(false);
    makePayment();
  };

  const onCancelConfirmation = (action, section) => {
    setIsConfirmationModalVisible(false);
    setIsPaymentInProgress(false);
  };

  const handlePayment = () => {
    setIsConfirmationModalVisible(true);
  };

  return (
    <div>
      <center>
        <h2 className="welcome-message">Payment Test (MOBILE):</h2>
      </center>
      <button onClick={handlePayment} disabled={isPaymentInProgress}>
        {isPaymentInProgress
          ? "Submitting payment..."
          : "$ Click Here to Make a Test Payment"}
      </button>
      {isConfirmationModalVisible && (
        <Popup
          message={
            "This action will perform a REAL payment in a REAL account. Are you sure you would like to do it?"
          }
          action={"Make a Payment"}
          value={"payment"}
          onCancel={onCancelConfirmation}
          onContinue={onContinueConfirmation}
        />
      )}
      {isPaymentInProgress && <Spinner />}
      {paymentTestResult.details !== "" && (
        <PaymentResultCell test={paymentTestResult} />
      )}
    </div>
  );
};
