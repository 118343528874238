import React, { useState, useEffect } from 'react';

const JsonViewer = ({ data }) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const jsonString = JSON.stringify(data, null, 2);

  const style = {
    whiteSpace: 'pre-wrap',
    fontFamily: 'monospace',
    fontSize: '14px',
    color: 'yellow', // Default color for the text
    width: `${width * 0.5}px`, // Adjust the width dynamically
    margin: 'auto', // Center the component horizontally
  };

  // Regular expression to match keys and values
  const regex = /(".*?":)|('.*?':)|(:\s*".*?"\s*,?)|(:\s*'.*?'\s*,?)/g;

  // Replace keys and values with styled spans
  const styledJsonString = jsonString.replace(regex, (match) => {
    if (/^"/.test(match)) { // If it's a key (double quotes)
      return `<span style="color: #8bc34a;">${match}</span>`;
    } else if (/^'/.test(match)) { // If it's a key (single quotes)
      return `<span style="color: #8bc34a;">${match}</span>`;
    } else if (/:$/.test(match)) { // If it's a colon
      return `<span style="color: #8bc34a;">${match}</span>`;
    } else { // If it's a value
      return `<span style="color: yellow;">${match}</span>`;
    }
  });

  return <div style={style} dangerouslySetInnerHTML={{ __html: styledJsonString }} />;
};

export default JsonViewer;
