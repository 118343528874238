import React, { useState } from "react";
import Spinner from "../../Tab1/components/Spinner";
import ChartComponent from "../../Tab4/components/ChartComponent";

export const SalesforceTest = ({
  orgLimitsTest,
  outboundCommTest,
  errorRateTest,
}) => {
  return (
    <div>
      <div className="historical-requests-container">
        <center>
          <h2 className="welcome-message">Historical Requests (SALESFORCE):</h2>
          {!errorRateTest?.errorLog && <Spinner />}
        </center>
        <center>
          {errorRateTest?.errorLog !== "" && (
            <div
              className="test-name"
              style={{ whiteSpace: "pre-line", width: "300px" }}
            >
              <br></br>
              {errorRateTest?.errorLog}
            </div>
          )}
        </center>
      </div>
      <div className="outbound-comms-container">
        <center>
          <h2 className="welcome-message">SMS Comms test(SALESFORCE):</h2>
          {!outboundCommTest.errorLog && <Spinner />}
        </center>
        <center>
          {outboundCommTest.errorLog !== "" && (
            <div
              className="test-name"
              style={{ whiteSpace: "pre-line", width: "300px" }}
            >
              <br></br>
              {outboundCommTest.errorLog}
            </div>
          )}
        </center>
      </div>
      <div className="governor-limits-container">
        <center>
          <h2 className="welcome-message">Governor Limits (SALESFORCE):</h2>
          {!errorRateTest?.errorLog && <Spinner />}
        </center>
        <center>
          {orgLimitsTest?.errorLog !== "" && (
            <div
              className="test-name"
              style={{ whiteSpace: "pre-line", width: "300px" }}
            >
              <br></br>
              {orgLimitsTest.errorLog}
            </div>
          )}
        </center>
      </div>
      {errorRateTest?.result?.data && (
        <div>
        <div id="chart-callouts">
          <ChartComponent
            data={errorRateTest?.result?.data}
            title={"Apex Callouts"}
            ylabel={"Requests"}
            metric={"numberOfRequests"}
          />
        </div>
        <div id="chart-50x">
          <ChartComponent
            data={errorRateTest?.result?.data}
            title={"Errors (50x)"}
            ylabel={"Errors"}
            metric={"errors500"}
          />
        </div>
        <div id="chart-40x">
          <ChartComponent
            data={errorRateTest?.result?.data}
            title={"Errors (40x)"}
            ylabel={"Errors"}
            metric={"errors400"}
          />
        </div>
        </div>
        
      )}
    </div>
  );
};
