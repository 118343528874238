import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  TimeScale,
  LineController,
  LineElement,
} from "chart.js";
import "chartjs-adapter-moment";
import Spinner from "../../Tab1/components/Spinner";

Chart.register(
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  TimeScale,
  LineController,
  LineElement
);

const ChartComponent = ({ data, title, ylabel, metric }) => {
  const colors = ["red", "blue", "green", "orange", "purple"];
  const datasets = data.map((resource, index) => ({
    label: resource.resourceShortName,
    data: resource.errorRates.map((rate) => ({
      x: new Date(rate.timestamp),
      y: rate[metric]
    })),
    fill: false,
    borderColor: colors[index % colors.length],
    tension: 0.1,
  }));

  console.log(JSON.stringify(datasets, null, 2));

  const chartData = {
    datasets: datasets,
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        ticks: {
          color: "white"
        }
      },
      y: {
        title: {
          display: true,
          text: ylabel,
          color: "white"
        },
        ticks: {
          color: "white"
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: "white"
        },
      },
    },
  };

  return (
    <div>
      <center>
        <h2 className="welcome-message">{title}</h2>
      </center>
      {data.length !== 0 ? 
      <Line data={chartData} options={options} /> : 
      <Spinner/> 
  }
    </div>
  );
};

export default ChartComponent;
