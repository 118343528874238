import React, { useState, useEffect } from "react";
import "../../css/CustomStyles.css";
import { AccountFinder } from "./components/AccountFinder";
import { AccountLinker } from "./components/AccountLinker";

export const Tab3Content = () => {

  return (
    <div className="tab3-container">
    <div >
      <AccountFinder/>
    </div>
    <div >
      <AccountLinker/>
    </div>
    </div>
  )

}