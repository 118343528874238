import React, { useState, useEffect } from "react";
import "../../../css/CustomStyles.css";
import { Api } from "../../../remote/api";
import Spinner from "../../Tab1/components/Spinner";
import JsonViewer from "./JsonViewer";

export const AccountLinker = () => {
  const [responseData, setResponseData] = useState({});
  const [accountList, setAccountList] = useState("");
  const [username, setUsername] = useState("");
  const [isLinking, SetIsLinking] = useState(false);
  const [body, setBody] = useState({});

  useEffect(()=> {
    setBody(createBody());
  },[accountList, username])

  const createBody = () => {
    const accounts = accountList.includes(",")
      ? accountList.split(",")
      : [accountList];

    const areAccountNumbersValid = accounts.every((account) =>
      account.length === 12
    );
    if (username.includes("@") && username.includes(".com") && areAccountNumbersValid) {
      return {
        email: username,
        accountList: accounts,
      };
    } else {
      return {};
    }
  };

  const handleSubmission = async (e) => {
    e.preventDefault();
    SetIsLinking(true);
    if (body?.email) {
      const result = await Api.accountLinker(body);
      if (result) {
        setResponseData(result);
      }
    }
    SetIsLinking(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "username") {
      setUsername(value);
    } else if (name === "accountList") {
      setAccountList(value);
    }
  };

  return (
    <div>
      <center>
        <h2 className="welcome-message">Test Account Linker</h2>
      </center>
      <center>
        <div className="tab3-subcontainer">
          <label>Credentials (user email):</label>
          <input
            name="username"
            className="account-finder-dropdown"
            placeholder="e.g. qa+entergy401@mindgrub.com"
            onChange={handleChange}
          />
          <label>Accounts to Link (comma separated):</label>
          <input
            name="accountList"
            className="account-finder-dropdown"
            placeholder="e.g. 000178174470"
            minLength={12}
            onChange={handleChange}
          />
        </div>
      </center>
      <center>
        <form onSubmit={handleSubmission}>
          {!isLinking ? (
            <button
              disabled={!body?.email}
              type="submit"
              className="account-finder-search-button"
            >
              LINK
            </button>
          ) : (
            <Spinner />
          )}
        </form>
      </center>
      <center>
      { responseData?.linked && (
        <div>
          <JsonViewer data={responseData} />
        </div>
      )}
      </center>
    </div>
  );
};
