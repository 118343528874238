import React, { useState, useEffect } from "react";
import "../../css/CustomStyles.css";
import { Api } from "../../remote/api";
import { Tab1Content } from ".././Tab1/Tab1";
import { Tab2Content } from ".././Tab2/Tab2";
import { Tab3Content } from ".././Tab3/Tab3";
import { Tab4Content } from ".././Tab4/Tab4";
import { Tab5Content } from ".././Tab5/Tab5";

const Main = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [downtime, setDowntime] = useState("");
  const [downtimeDownloadURL, setDowntimeDownloadURL] = useState("");

  useEffect(() => {
    getDowntime();
  }, []);

  const getDowntime = async () => {
    const result = await Api.getDowntime();

    if (result && result.durationInHours) {
      setDowntime(result.durationInHours);
      setDowntimeDownloadURL(result.downloadUrl);
    }
  };

  return (
        <div className="child-container">
          <div className="main-menu">
            <h2 className="welcome-message">
              {process.env.REACT_APP_API_ENDPOINT ===
              "https://xanqgear77.execute-api.us-east-1.amazonaws.com/dev"
                ? "Mobile App System Check - Dev"
                : "Mobile App System Check- Prod"}
            </h2>
            <a
              href={downtimeDownloadURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h4 className="welcome-message">Downtime YTD: {downtime}</h4>
            </a>
            <div className="tab-selectors">
              <button
                className={`tab-button ${activeTab === "tab1" ? "active" : ""}`}
                onClick={() => setActiveTab("tab1")}
              >
                System Status
              </button>
              <button
                className={`tab-button ${activeTab === "tab2" ? "active" : ""}`}
                onClick={() => setActiveTab("tab2")}
              >
                App Config
              </button>
              {process.env.REACT_APP_API_ENDPOINT ===
                "https://xanqgear77.execute-api.us-east-1.amazonaws.com/dev" && (
                <button
                  className={`tab-button ${
                    activeTab === "tab3" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("tab3")}
                >
                  Dev Tools
                </button>
              )}
              <button
                className={`tab-button ${activeTab === "tab4" ? "active" : ""}`}
                onClick={() => setActiveTab("tab4")}
              >
                Reports
              </button>
              <button
                className={`tab-button ${activeTab === "tab5" ? "active" : ""}`}
                onClick={() => setActiveTab("tab5")}
              >
                Salesforce
              </button>
              {/* <button onClick={handleLogout}>Logout</button> */}
            </div>
          </div>
          <div className="tab-content">
            {activeTab === "tab1" && <Tab1Content />}
            {activeTab === "tab2" && <Tab2Content />}
            {activeTab === "tab3" && <Tab3Content />}
            {activeTab === "tab4" && <Tab4Content />}
            {activeTab === "tab5" && <Tab5Content />}
          </div>
        </div>
  );
};

export default Main;
