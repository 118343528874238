import React, { useState, useEffect } from "react";
import "../../../css/CustomStyles.css";
import { Api } from "../../../remote/api";
import Spinner from "../../Tab1/components/Spinner";
import JsonViewer from "./JsonViewer";

export const AccountFinder = () => {
  const [accountData, setAccountData] = useState({});
  const [isFinding, setIsFinding] = useState(false);
  const [exactMatch, setExactMatch] = useState("");
  const [formData, setFormData] = useState({
    accountStatus: "Any",
    companyCode: "Any",
    autoPay: "Any",
    DNAC: "Any",
    hasElectric: "Any",
    hasGas: "Any",
    hasLight: "Any",
    prepay: "Any",
  });

  const createBody = () => {
    const body = {};
    if (exactMatch !== "") {
      body.contractAccountNumber = exactMatch;
    } else {
      Object.keys(formData).forEach((key) => {
        if (formData[key] !== "Any") {
          if (key === "accountStatus" || key === "companyCode") {
            body[key] = formData[key];
          } else {
            body[key] = JSON.parse(formData[key]);
          }
        }
      });
    }
    return body;
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsFinding(true);
    setAccountData({});
    const body = createBody();
    const result = await Api.accountFinder(body);
    setIsFinding(false);
    if (result) {
      setAccountData(result);
    }
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === "text") {
      setExactMatch(value);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  return (
    <div className=".account-finder-container">
      <center>
        <h2 className="welcome-message">Test Account Finder</h2>
      </center>
      <center>
        <div className="tab3-subcontainer">
          <label>
            Exact Match:
            <input
              className="account-finder-dropdown"
              placeholder="e.g. 000178174470"
              minLength={12}
              maxLength={12}
              onChange={handleChange}
            />
          </label>
          <label>
            Account Status:
            <select
              disabled={exactMatch !== ""}
              className="account-finder-dropdown"
              name="accountStatus"
              value={formData.accountStatus}
              onChange={handleChange}
            >
              <option value="Any">Any</option>
              <option value="A">Active</option>
              <option value="F">Final</option>
              <option value="PI">Pending Initiation</option>
              <option value="PA">Pending Activation</option>
              <option value="PF">Pending Final</option>
              <option value="D">Disconnected</option>
            </select>
          </label>

          <label>
            Company Code:
            <select
              disabled={exactMatch !== ""}
              className="account-finder-dropdown"
              name="companyCode"
              value={formData.companyCode}
              onChange={handleChange}
            >
              <option value="Any">Any</option>
              <option value="4000">4000</option>
              <option value="5000">5000</option>
              <option value="6000">6000</option>
              <option value="7000">7000</option>
              <option value="8000">8000</option>
              <option value="9000">9000</option>
            </select>
          </label>

          <label>
            Has Electric:
            <select
              disabled={exactMatch !== ""}
              className="account-finder-dropdown"
              name="hasElectric"
              value={formData.hasElectric}
              onChange={handleChange}
            >
              <option value="Any">Any</option>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </label>

          <label>
            Has Gas:
            <select
              disabled={exactMatch !== ""}
              className="account-finder-dropdown"
              name="hasGas"
              value={formData.hasGas}
              onChange={handleChange}
            >
              <option value="Any">Any</option>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </label>

          <label>
            Has Light:
            <select
              disabled={exactMatch !== ""}
              className="account-finder-dropdown"
              name="hasLight"
              value={formData.hasLight}
              onChange={handleChange}
            >
              <option value="Any">Any</option>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </label>

          <label>
            Auto Pay:
            <select
              disabled={exactMatch !== ""}
              className="account-finder-dropdown"
              name="autoPay"
              value={formData.autoPay}
              onChange={handleChange}
            >
              <option value="Any">Any</option>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </label>

          <label>
            DNAC:
            <select
              disabled={exactMatch !== ""}
              className="account-finder-dropdown"
              name="DNAC"
              value={formData.DNAC}
              onChange={handleChange}
            >
              <option value="Any">Any</option>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </label>

          <label>
            Prepay:
            <select
              disabled={exactMatch !== ""}
              className="account-finder-dropdown"
              name="prepay"
              value={formData.prepay}
              onChange={handleChange}
            >
              <option value="Any">Any</option>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </label>
        </div>
      </center>
      <center>
        <form onSubmit={handleSearch}>
          {!isFinding ? (
            <button type="submit" className="account-finder-search-button">
              SEARCH
            </button>
          ) : (
            <Spinner />
          )}
        </form>
      </center>
      { accountData?.accountOverviewSet && (
        <div className="account-data">
          <JsonViewer data={accountData} />
        </div>
      )}
    </div>
  );
};
