import React, { useState, useEffect } from "react";
import "../../css/CustomStyles.css";
import { Api } from "../../remote/api";
import { SalesforceTest } from "./components/SalesforceTest";

export const Tab5Content = () => {
  const [orgLimitsTest, setOrgLimitsTestLog] = useState({});
  const [outboundCommTest, setOutboundCommTestLog] = useState({});
  const [errorRateTest, setErrorRateTestLog] = useState({});

  useEffect(() => {
    getSalesforceCheck();
  }, []);

  const getSalesforceCheck = async () => {
    const result = await Api.salesforceTest();
    if (result) {
      setOrgLimitsTestLog(result.orgLimitsTest);
      setOutboundCommTestLog(result.outboundCommTest);
      setErrorRateTestLog(result.errorRateTest);
    }
  };

  return (
    <div className="tab5-container">
      <div className="tab1-healthCheck-container">
        {
          <SalesforceTest
            orgLimitsTest={orgLimitsTest}
            outboundCommTest={outboundCommTest}
            errorRateTest={errorRateTest}
          />
        }
      </div>
    </div>
  );
};
