import React, { useState, useEffect } from "react";
import "../css/CustomStyles.css";
import { Api } from "../remote/api";
import Main from "./Main/Main";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const successfullLogIn = () => {
    setIsLoggedIn(true);
  };

  const redirectToAuthIfNeeded = () => {
    if (Api.accessToken === "") {
      console.log("NO ACCESSTOKEN PRESENT, REDIRECTING TO AUTH");
      window.location.href = `${process.env.REACT_APP_COGNITO_AUTH_ENDPOINT}`;
    } else {
      successfullLogIn();
    }
  };

  const handleAuthorization = async () => {
    const queryString = window.location.search.substring(1);
    const authorization = queryString.split("=");
    if (authorization[1]) {
      console.log("AUTHORIZATION", authorization[1]);
      setIsLoading(true);

      const result = await Api.getAccessToken(authorization[1]);

      if (result) {
        successfullLogIn();
      } else {
        redirectToAuthIfNeeded();
      }
      //setIsLoading(false);
    } else {
      redirectToAuthIfNeeded();
    }
  };
  useEffect(() => {
    handleAuthorization();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const result = await Api.login(username, password);

    if (result) {
      setIsLoggedIn(true);
    }
    setIsLoading(false);
  };

  // const handleLogout = () => {
  //   Api.accessToken = "";
  //   setUsername("");
  //   setPassword("");
  //   setIsLoggedIn(false);
  //   setActiveTab("tab1");
  //   handleAuthorization();
  // };

  return (
    <div className="main-container">
      {isLoggedIn ? (
        <Main />
      ) : (
        <div className="container">
          <div className="login-form">
            <h2>Login</h2>
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <label htmlFor="username">Username:</label>
                <input
                  className="login-input"
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  disabled={isLoading}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password:</label>
                <input
                  className="login-input"
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={isLoading}
                />
              </div>
              <button type="submit" disabled={isLoading}>
                {isLoading ? "Logging in..." : "Login"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
